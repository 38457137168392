<template>
  <div class="staffedit-box">
    <div class='bg-whilt editInfo'>
      <!-- <label class='label' v-if="formData.id">
        <span class='labelTitle'>门店ID</span>
        <input type='text' maxlength='50' disabled="true" placeholder="门店ID" v-model='formData.id' />
      </label> -->
      <label class='label'>
        <span class='labelTitle'>终端key</span>
        <div class="right-cont">
          <span>{{formData.termKey}}</span>
          <input type="text" ref="key" v-model="formData.termKey" class="hidden">
          <div class="copy-btn center" v-if="formData.termKey!=null" @click='copyText("key")'>复制</div>
        </div>
      </label>
      <label class='label'>
        <span class='labelTitle'>终端加密key</span>
        <div class="right-cont">
          <span>{{formData.termPrivateKey}}</span>
          <input type="text" ref="pwdkey" v-model="formData.termPrivateKey" class="hidden">
          <div class="copy-btn center" v-if="formData.termPrivateKey!=null" @click='copyText("pwdkey")'>复制</div>
        </div>
      </label>
    </div>

    <div class='bg-whilt editInfo'>
      <label class='label'>
        <span class='labelTitle'>终端名称 <span class="mcolor">*</span></span>
        <input type='text' maxlength='50' placeholder="终端名称" v-model='formData.termName' bindinput='bindInput' data-name="termName" />
      </label>
      <label class='label' @click="showStoreBox">
        <span class='labelTitle'>所属门店 <span class="mcolor">*</span></span>
        <input type="text" disabled="true" placeholder="请选择所属门店" v-model="formData.shopName" />
        <div class="more"></div>
      </label>
      <van-popup v-model:show="showStore" round position="bottom">
          <van-picker
            :columns="StoreList"
            :columns-field-names="customFieldName"
            @cancel="showStore = false"
            @confirm="onConfirm"
          />
        </van-popup>
      <label class='label'>
        <span class='labelTitle'>是否启用</span>
        <div class="right-cont">
          <van-switch v-model="formData.status" active-color="#FA3474" size="24px" inactive-color="#E5E5E5" />
        </div>
      </label>
    </div>
    <div class="opt-box">
      <button class='btn apply-btn center float-ani' :disabled="buttonClicked" @click="submitForm">保存</button>
    </div>  
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import {  getStoreListApi } from '@/api/store'
import { getTermDetail, saveTermPage } from '@/api/term'
export default {
  name:'termedit',
  setup() {
    const route = useRoute()
    const router = useRouter()

    const state = reactive({
      formData: {},
      id: null,
      buttonClicked: false,
      customFieldName: {
        text: 'shopName'
      },
      StoreList: [],
      showStore: false
    })

    onMounted(async() => {
      var toast1 = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      const { rows } = await getStoreListApi()
      state.StoreList = rows;
      const { id } = route.params
      if (!id) return;
      const { data } = await getTermDetail(id)
      state.id = id;
      data.status = !data.status
      state.formData = data;
      toast1.clear();
    })

    const showStoreBox = () => {
      state.showStore = true
    }

    const onConfirm = (e) => {
      state.showStore = false
      state.formData.shopName = e.shopName;
      state.formData.shopId = e.id;
    }

    
    const {proxy} = getCurrentInstance()
    const copyText = (inputDom) => {
      proxy.$refs[inputDom].select() //选中文本
      document.execCommand('Copy', false) // 执行浏览器复制命令
    }

    // 保存
    const submitForm = () => {
      if(!state.formData.termName){
        Toast('请填写终端名称！');
        return false;
      }
      if(!state.formData.shopName){
        Toast('请选择所属门店！');
        return false;
      }
      state.buttonClicked = true
      let url = state.id ? '/wxapp/merTerm/edit' : '/wxapp/merTerm/add'
      const form = {
        "id": state.formData.id,
        "merId": state.formData.merId,
        "remark": state.formData.remark,
        "shopId": state.formData.shopId,
        "status": state.formData.status ? 0 : 1,
        "termName": state.formData.termName,
        "termType": state.formData.termType,
      };
      saveTermPage(url, form).then(res => {
        Toast(res.msg);
        state.buttonClicked = false
        if (res.code == 200) {
          setTimeout(() => {
            router.go(-1)
          },1500)
        }
      })
    }

    return {
      ...toRefs(state),
      onConfirm,
      showStoreBox,
      submitForm,
      copyText
    }
  }
};
</script>

<style lang="less" scoped>
.staffedit-box{
  width: 100%;
  height: 100vh;
  background: #efefef;
  .hidden{
    position: absolute;
    z-index: -1;
  }
}
.mcolor{
  color: #FA3474;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.editInfo {
  background: #fff;
  line-height: 30px;
  margin-bottom: 13px;
  border-bottom: solid 1px #f2f2f2;
  border-top: solid 1px #f2f2f2;
  font-size: 15px;
  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #f2f2f2;
    justify-content: space-between;
    padding-left: 1px;
    padding: 9px 15px;
    &:last-child {
      border: none;
    }
    .labelTitle{
      width: 90px;
      flex: none;
    }
    .right-cont{
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .copy-btn{
      width: 39px;
      height: 17px;
      background: #FFFFFF;
      border: 1px solid #FA3474;
      border-radius: 2px;
      font-size: 12px;
      color: #FA3474;
      margin-left: 21px;
    }
    input {
      width: 235px;
      text-align: right;
      border: none;
      &:disabled{
        background: none;
      }
    }
    .more {
      flex: none;
      width: 7px;
      height: 7px;
      border-top: 1px solid #888;
      border-right: 1px solid #888;
      transform: rotate(45deg);
    }
  }
}

.opt-box {
  height: 89px;
  padding-bottom: env(safe-area-inset-bottom);
  .apply-btn {  
    position: fixed;
    bottom: 15px;
    padding: 0;
    left: 4%;
    width: 92%;
    height: 44px;
    background: #FA3474;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: env(safe-area-inset-bottom);
    border: none;
  }
}
</style>