
import request from "@/utils/request";


//查询门店列表
export function getStoreListApi(data) {
  return request({
    url: '/wxapp/merShop/list',
    method: 'get',
    params: data
  })
}

// 门店详情
export function getStoreDetail(id) {
  return request({
    url: `/wxapp/merShop/detail?id=${id}`,
    method: 'get'
  })
}

// 门店编辑

export function saveStorePage(url, data) {
  return request({
    url: url,
    method: 'post',
    data
  })
}