
import request from "@/utils/request";


//查询终端列表
export function getTermListApi() {
  return request({
    url: '/wxapp/merTerm/baseList',
    method: 'get'
  })
}


// 终端列表
export function getTermList(data) {
  return request({
    url: '/wxapp/merTerm/list',
    method: 'get',
    data
  })
}
// 终端详情
export function getTermDetail(id) {
  return request({
    url: `/wxapp/merTerm/detail?id=${id}`,
    method: 'get'
  })
}

// 终端编辑
export function saveTermPage(url, data) {
  return request({
    url: url,
    method: 'post',
    data
  })
}